import React from 'react';
import classes from './Spinner.module.scss';

const spinner = () => {
    return <div className={classes.loader}>
                
            </div>
}


export default spinner;