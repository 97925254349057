import classes from './Badge.module.scss'
import f2 from '../../assets/images/f2.png';
import gia1 from '../../assets/images/gia/gia1.jpg';
import gia2 from '../../assets/images/gia/gia2.jpg';
import gia3 from '../../assets/images/gia/gia3.jpg';
import gia4 from '../../assets/images/gia/gia4.jpg';
import gia5 from '../../assets/images/gia/gia5.png';
import gia6 from '../../assets/images/gia/gia6.png';
import React, { Component} from 'react';



class Badge extends Component {

    state = {
        imageCounter : 0,
        images : [
            f2, 
            gia1,
            gia2,
            gia3,
            gia4,
            gia5,
            gia6,
        ],
        fadeIn: false
           
    }

    changeImage = () => {
        const counter = this.state.imageCounter;
        const imagesNumber = this.state.images.length - 1          
        if ( imagesNumber === counter) {
            this.setState({imageCounter: 0})
        } else {
            let incrementCounter = this.state.imageCounter + 1;
            this.setState({imageCounter: incrementCounter})
        }
        this.setState({fadeIn: true})
    }


    render () {
        let classFade = '';         
        if (this.state.fadeIn === true) {
            classFade = classes.FadeInImage
            setTimeout(() => {
                this.setState({fadeIn: false})
            }, 650); 
        }
        
        return (
            <div className={classes.Container}>
                <img className={classFade} onClick={this.changeImage} src={this.state.images[this.state.imageCounter]} alt='#'/>
                <span></span>
                <span></span>
                <span></span>
            </div>
        )
    }
}


export default Badge;