import './App.module.scss';
import React, {Component} from 'react';
import Content from './container/Content/Content';
import Login from './container/Login/Login';
import {connect} from 'react-redux';
import {authCheckState} from './store/actions/authAction';

class App extends Component {
  
  constructor(props) {
    super(props)
    this.scrollRef = React.createRef();
  }

  componentDidMount() {
    this.props.onAuthCheckState() 
  }

  scollToContent = () => {    
    
    window.scrollTo(0, this.scrollRef.current.offsetTop) 
  }
  
  render () {
    let oContent = null;
    
    if (this.props.isAuth === true) {
      oContent = <Content/>           
    }
    
    if (this.props.isLoading !== true && this.props.isAuth === true) {
        this.scollToContent()
    }

    return (
      <div className="App">
          <Login/>
          <div ref={this.scrollRef}>{oContent}</div>                 
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      isAuth: state.authReducer.token !== null && state.authReducer.userId !== null,
      isLoading: state.authReducer.isLoading      
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAuthCheckState : () => {dispatch(authCheckState())}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
