
const initialState = {
    isLoading : false,
    token: null,
    userId: null,
    content: null    
}


const authSuccess = (state, action) => {
    return {
        ...state,
        ...{ token: action.token, userId: action.userId }
    }
}

const updateLoading = (state, action) => {
    return {
        ...state,
        ...{isLoading : action.isLoading}
    }
}

const logout = (state, action) => {
    return {
        ...state,
        ...{ token: null, userId: null }
    }
}

const updateContent = (state, action) => {
    return {
        ...state,
        ...{content: action.content}
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ('AUTH_SUCCESS'): return authSuccess(state, action);         
        case ('UPDATE_LOADING'): return updateLoading(state, action);         
        case ('UPDATE_CONTENT'): return updateContent(state, action);         
        case ('LOGOUT'): return logout(state, action);         
        default: return state;
    }
};

export default reducer;