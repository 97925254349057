import React, { Component } from 'react';
import classes from './Login.module.scss'
import { FaLock, FaPowerOff } from "react-icons/fa";
import {connect} from 'react-redux';
import {auth, logout} from '../../store/actions/authAction';
import Spinner from '../../Components/UI/Spinner/Spinner';


class Login extends Component {

    state = {
        password: ''
    }

    submitForm = (event) => {
        event.preventDefault()
        this.props.onAuth(this.state.password)
        const updatedPassword = {
            ...this.state,
            password : ''
        }        
        this.setState(updatedPassword)
    }

    inputChangedHandler = (event) => {
        const updatedPassword = {
            ...this.state,
            password : event.target.value
        }        
        this.setState(updatedPassword)
    }

    logout = () => {        
        this.props.onLogout()
    }

    render () {
        let oLoginContent = null;
        
        if (this.props.isLoading === true) {
            oLoginContent = (
                <div className={classes.SpinnerCont}>
                    <Spinner/>
                </div>
            )
        }
       
        if (this.props.isLoading !== true && this.props.isAuth === true) {
            oLoginContent = (
                <div className={classes.LogOutContainer}>
                    <span onClick={this.logout}><FaPowerOff/></span>                
                </div>
            )
        }

        if (this.props.isLoading !== true && this.props.isAuth !==true) {
            oLoginContent = (
                <div className={classes.FormContainer}>
                    <form onSubmit={this.submitForm}>
                        <span><FaLock/></span>
                        <input type='password' name="password" autoComplete="on" className={classes.Input} onChange={this.inputChangedHandler} />                    
                        <label className={classes.Label} htmlFor={classes.Input}>PASSWORD</label>
                        <button>SUBMIT</button>          
                    </form>
                </div>
            )
        }

        return (
            <div className={classes.MainContainer}>
                {oLoginContent}
            </div>
        )
    }


}

const mapStateToProps = state => {
    return {
        isAuth: state.authReducer.token !== null,
        isLoading: state.authReducer.isLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (password) => dispatch(auth(password)),
        onLogout: () => dispatch(logout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
